import { graphql } from 'gatsby';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import { Markdown } from '../components/Markdown';
import { BaseProps } from '../models/BaseProps';
import { GraphSFC } from '../models/GraphSFC';
import { renderLive, renderPreview } from '../utils/render';

export interface HomeProps extends BaseProps {
  description: string;
}

export const Home: React.SFC<HomeProps> = ({ title, description }) => {
  return (
    <div>
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2>
      <Button color="danger">Danger!</Button>
      <Markdown content={description} />
    </div>
  );
};

export const HomeLive: GraphSFC<HomeProps> = props => {
  return renderLive(props, p => <Home {...p} />);
};

export const HomePreview = props => {
  return renderPreview<HomeProps>(props, p => <Home {...p} />);
};

export default HomeLive;

export const homeQuery = graphql`
  query Home($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
