import React from 'react';
import '../css/Layout.scss';

export interface PreviewLayoutProps {}

export const PreviewLayout: React.SFC<PreviewLayoutProps> = ({ children }) => {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
        integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
        crossOrigin="anonymous"
      />
      {children}
    </div>
  );
};
