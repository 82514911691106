import React from 'react';
import { Layout } from '../components/Layout';
import { PreviewLayout } from '../components/PreviewLayout';
import { BaseProps } from '../models/BaseProps';
import { GraphProps } from '../models/GraphProps';

export function renderLive<T extends BaseProps>(props: GraphProps<T>, render: (props: T) => React.ReactElement<T>) {
  const componentProps = props.data.markdownRemark.frontmatter;
  return <Layout title={componentProps.title}>{render(componentProps)}</Layout>;
}

export function renderPreview<T extends BaseProps>(props: any, render: (props: T) => React.ReactElement<T>) {
  const componentProps = props.entry.toJS().data as T;
  return <PreviewLayout>{render(componentProps)}</PreviewLayout>;
}
